import intro01 from "./01_intro.md";
import let02 from "./02_let.md";
import destructuring03 from "./03_destructuring.md";
import string04 from "./04_string.md";
import stringMethods05 from "./05_string-methods.md";
import regex06 from "./06_regex.md";
import number07 from "./07_number.md";
import function08 from "./08_function.md";
import array09 from "./09_array.md";
import object10 from "./10_object.md";
import objectMethods11 from "./11_object-methods.md";
import operator12 from "./12_operator.md";
import symbol13 from "./13_symbol.md";
import setMap14 from "./14_set-map.md";
import proxy15 from "./15_proxy.md";
import reflect16 from "./16_reflect.md";
import promise17 from "./17_promise.md";
import iterator18 from "./18_iterator.md";
import generator19 from "./19_generator.md";
import generatorAsync20 from "./20_generator-async.md";
import async21 from "./21_async.md";
import class22 from "./22_class.md";
import classExtends23 from "./23_class-extends.md";
import module24 from "./24_module.md";
import moduleLoader25 from "./25_module-loader.md";
import style26 from "./26_style.md";
import spec27 from "./27_spec.md";
import asyncIterator28 from "./28_async-iterator.md";
import arraybuffer29 from "./29_arraybuffer.md";
import proposals30 from "./30_proposals.md";
import decorator31 from "./31_decorator.md";
import reference32 from "./32_reference.md";

export default {
  uuid: "00000ES6-AAAA-BBBB-CCCC-000000000000",
  name: "ES6 标准入门（第3版）",
  url: "https://assets.jiker.com/_for_common_project/2022/0301/admin/kCxpLFlHLsDFT32pSFiJ7z4brmMRoIZKDIbKz6nO.jpeg",
  contents: [
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000001",
      name: "ECMAScript 6 简介",
      content: intro01,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000002",
      name: "let 和 const 命令",
      content: let02,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000003",
      name: "变量的解构赋值",
      content: destructuring03,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000004",
      name: "字符串的扩展",
      content: string04,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000005",
      name: "字符串的新增方法",
      content: stringMethods05,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000006",
      name: "正则的扩展",
      content: regex06,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000007",
      name: "数值的扩展",
      content: number07,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000008",
      name: "函数的扩展",
      content: function08,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000009",
      name: "数组的扩展",
      content: array09,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000010",
      name: "对象的扩展",
      content: object10,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000011",
      name: "对象的新增方法",
      content: objectMethods11,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000012",
      name: "运算符的扩展",
      content: operator12,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000013",
      name: "Symbol",
      content: symbol13,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000014",
      name: "Set 和 Map 数据结构",
      content: setMap14,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000015",
      name: "Proxy",
      content: proxy15,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000016",
      name: "Reflect",
      content: reflect16,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000017",
      name: "Promise 对象",
      content: promise17,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000018",
      name: "Iterator 和 for...of 循环",
      content: iterator18,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000019",
      name: "Generator 函数的语法",
      content: generator19,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000020",
      name: "Generator 函数的异步应用",
      content: generatorAsync20,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000021",
      name: "async 函数",
      content: async21,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000022",
      name: "Class 的基本语法",
      content: class22,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000023",
      name: "Class 的继承",
      content: classExtends23,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000024",
      name: "Module 的语法",
      content: module24,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000025",
      name: "Module 的加载实现",
      content: moduleLoader25,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000026",
      name: "编程风格",
      content: style26,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000027",
      name: "读懂 ECMAScript 规格",
      content: spec27,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000028",
      name: "异步遍历器",
      content: asyncIterator28,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000029",
      name: "ArrayBuffer",
      content: arraybuffer29,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000030",
      name: "最新提案",
      content: proposals30,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000031",
      name: "装饰器",
      content: decorator31,
    },
    {
      uuid: "00000ES6-AAAA-BBBB-CCCC-000000000032",
      name: "参考链接",
      content: reference32,
    },
  ],
};
