


















































































































import Vue from "vue";
import { mapState } from "vuex";
import lxp from "@/mock";
import MarkdownIt from "jk-markdown-it/MarkdownIt.vue";

interface AnyObject {
  [key: string]: any;
}

export default Vue.extend({
  components: {
    MarkdownIt,
  },
  data(): {
    loading: boolean;
    darkMode: boolean;
    title: string;
    subtitle: string;
    contents: AnyObject[];
    content: string;
    directoryVisible: boolean;
    descending: boolean;
  } {
    return {
      loading: true,
      darkMode: false,
      title: "",
      subtitle: "",
      contents: [],
      content: "",
      directoryVisible: false,
      descending: false,
    };
  },
  computed: {
    ...mapState(["userPhone", "userName", "userAvatar"]),
    dark(): string {
      return this.darkMode ? "dark" : "";
    },
    ending(): boolean {
      const subid: string = this.$route.query.subid as string;
      const length: number = this.contents.length;
      const endId: string = this.contents[length - 1].uuid;
      return subid === endId ? true : false;
    },
  },
  created() {
    this.getData();
    this.toggleBodyClassName();
  },
  mounted() {
    (this.$refs["directoryDialog"] as HTMLFormElement).$refs[
      "dialog"
    ].style.marginTop = 0;
  },
  watch: {
    $route: "getContent",
    darkMode: "toggleBodyClassName",
  },
  methods: {
    getData(): void {
      this.darkMode = localStorage.getItem("jk-mode") === "dark" ? true : false;
      const id: string = this.$route.params.id;
      const subid: string | undefined = this.$route.query.subid as
        | string
        | undefined;
      if (!subid) {
        lxp.forEach((data: AnyObject) => {
          if (id === data.uuid) {
            const subid: string = data.contents[0].uuid;
            this.$router.push({
              name: "Detail",
              params: { id },
              query: { subid },
            });
          }
        });
        this.getData();
        return;
      }
      lxp.forEach((data: AnyObject) => {
        if (id === data.uuid) {
          this.title = data.name;
          this.contents = data.contents;
        }
      });
      this.getContent();
    },
    getContent(): void {
      this.loading = true;
      const subid: string = this.$route.query.subid as string;
      this.contents.forEach((data: AnyObject) => {
        if (subid === data.uuid) {
          this.subtitle = data.name;
          this.content = data.content;
        }
      });
      document.documentElement.scrollTop = 0;
      this.loading = false;
    },
    toggleBodyClassName(): void {
      if (this.darkMode) document.body.className = "dark";
      else document.body.className = "";
    },
    goHomePage(): void {
      this.$router.push({ name: "Home" });
    },
    handleCommand(command: string): void {
      this[command]();
    },
    handleLogout(): void {
      localStorage.removeItem("jk_lxp_token");
      this.$router.replace({ name: "AccountLogin" });
    },
    showDirectory(): void {
      let currentIndex: number = 0;
      this.contents.forEach((data: AnyObject, index: number) => {
        if (data.uuid === this.$route.query.subid) currentIndex = index;
      });
      this.directoryVisible = true;
      this.$nextTick(() => {
        (this.$refs["directoryScrollbar"] as HTMLFormElement).wrap.scrollTop =
          currentIndex * 52;
      });
    },
    toggleSort(): void {
      this.descending = !this.descending;
      this.contents.reverse();
      this.contents.forEach((data: AnyObject, index: number) => {
        if (data.uuid === this.$route.query.subid) {
          (this.$refs["directoryScrollbar"] as HTMLFormElement).wrap.scrollTop =
            index * 52;
        }
      });
    },
    toggleContent(uuid: string): void {
      if (this.$route.query.subid === uuid) return;
      this.$router.push({
        name: "Detail",
        query: { subid: uuid },
      });
      this.directoryVisible = false;
    },
    goNext(): void {
      const subid: string = this.$route.query.subid as string;
      this.contents.forEach(
        (data: AnyObject, index: number, self: AnyObject[]) => {
          if (data.uuid === subid) {
            const id: string = self[index + 1].uuid;
            this.$router.push({
              name: "Detail",
              query: { subid: id },
            });
          }
        }
      );
    },
    toggleMode(): void {
      this.darkMode = !this.darkMode;
      if (this.darkMode) localStorage.setItem("jk-mode", "dark");
      else localStorage.setItem("jk-mode", "sun");
    },
  },
});
