






import Vue from "vue";

export default Vue.extend({
  name: "IconSvg",
  props: {
    svgClass: {
      type: String,
    },
    svgName: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName(): string {
      return `#icon-${this.svgName}`;
    },
  },
});
