

































































































































import Vue from "vue";
import { mapState } from "vuex";
import lxp from "@/mock";

interface AnyObject {
  [key: string]: any;
}

export default Vue.extend({
  data(): {
    mode: string;
    myFavorite: AnyObject[];
    guessYouLike: AnyObject[];
  } {
    return {
      mode: "sun",
      myFavorite: [],
      guessYouLike: [],
    };
  },
  computed: {
    ...mapState(["userPhone", "userName", "userAvatar"]),
  },
  created() {
    this.getData();
  },
  methods: {
    getData(): void {
      const mode: string | null = localStorage.getItem("jk-mode");
      this.mode = mode ? mode : "sun";
      if (this.mode === "dark") document.body.className = "dark";
      else document.body.className = "";
      this.getRandomArray(3).forEach((data: number, index: number) => {
        this.myFavorite[index] = lxp[data];
        this.myFavorite[index].description = `已学习 ${Math.floor(
          Math.random() * 60
        )} 分钟`;
      });
      this.getGuessYouLike();
    },
    getGuessYouLike(): void {
      const arr: {}[] = [];
      this.getRandomArray(4).forEach((data: number, index: number) => {
        arr[index] = lxp[data];
      });
      this.guessYouLike = arr;
    },
    getRandomArray(val: number): number[] {
      const arr: number[] = [];
      while (arr.length < val) {
        const randomValue: number = Math.floor(Math.random() * lxp.length);
        if (arr.indexOf(randomValue) === -1) arr.push(randomValue);
      }
      return arr;
    },
    goDetail(id: string): void {
      this.$router.push({
        name: "Detail",
        params: { id },
      });
    },
    handleCommand(command: string): void {
      this[command]();
    },
    handleLogout(): void {
      localStorage.removeItem("jk_lxp_token");
      this.$router.replace({ name: "AccountLogin" });
    },
  },
});
