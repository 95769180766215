var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('div',{staticClass:"login-page-header"},_vm._l((_vm.supportedLangs),function(lang,index){return _c('span',{key:index},[_c('el-link',{attrs:{"type":_vm.$i18n.locale === lang ? 'primary' : '',"underline":false},on:{"click":function($event){return _vm.changeLocale(lang)}}},[_vm._v(" "+_vm._s(_vm.langs[lang])+" ")]),(index !== _vm.supportedLangs.length - 1)?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e()],1)}),0),_c('div',{staticClass:"login-page-mainer"},[_c('div',{staticClass:"login-logo-box"},[_c('img',{staticClass:"login-logo",attrs:{"src":require("@/assets/images/logo.png")}}),_c('div',{staticClass:"login-logo_text"},[_vm._v(_vm._s(_vm.$t("common.brand")))])]),_c('el-form',{ref:"loginInfo",staticClass:"login-info",attrs:{"model":_vm.loginInfo,"rules":_vm.loginRules,"status-icon":""}},[_c('el-form-item',{attrs:{"prop":"phone_number","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'blur',
          },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: _vm.$t('error.login'),
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.form.phone'),"autocomplete":"off"},model:{value:(_vm.loginInfo.phone_number),callback:function ($$v) {_vm.$set(_vm.loginInfo, "phone_number", $$v)},expression:"loginInfo.phone_number"}})],1),_c('el-form-item',{attrs:{"prop":"password","show-message":false}},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('login.form.password'),"autocomplete":"off"},model:{value:(_vm.loginInfo.password),callback:function ($$v) {_vm.$set(_vm.loginInfo, "password", $$v)},expression:"loginInfo.password"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"login-btn",attrs:{"type":"primary","plain":""},on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.$t("common.login")))])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }