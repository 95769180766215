





































































import Vue from "vue";
import { SUPPORTED_LANGS, setLocale, default as i18n } from "@/locales/i18n";

export default Vue.extend({
  data(): {
    supportedLangs: string[];
    langs: {
      "en-US": string;
      "zh-CN": string;
      "fr-FR": string;
    };
    loginInfo: {
      phone_number: string;
      password: string;
    };
    loginRules: {
      password: {
        required: boolean;
        trigger: string;
      };
    };
  } {
    return {
      supportedLangs: SUPPORTED_LANGS,
      langs: {
        "en-US": "English",
        "zh-CN": "中文",
        "fr-FR": "Français",
      },
      loginInfo: {
        phone_number: "",
        password: "",
      },
      loginRules: {
        password: { required: true, trigger: "blur" },
      },
    };
  },
  methods: {
    changeLocale(lang: string): void {
      if (i18n.locale === lang) return;
      i18n.locale = lang;
      setLocale(lang);
    },
    login(): void {
      (this.$refs["loginInfo"] as HTMLFormElement).validate((val: boolean) => {
        if (!val) return;
        localStorage.setItem("jk_lxp_token", this.loginInfo.phone_number);
        this.$message.success(
          i18n.t("success.format", {
            attribute: i18n.t("common.login"),
          }) as string
        );
        this.$router.go(0);
      });
    },
  },
});
